.animated-gradient {
  background: linear-gradient(315deg, #fc5b05 0%, #fb5f5f 50%, #fa5903 100%);
  background-size: 200% 200%;
  animation: gradientMove 15s ease infinite;
}

@keyframes gradientMove {
  0% {
    background-position: 0% 0%;
  }

  50% {
    background-position: 100% 100%;
  }

  100% {
    background-position: 0% 0%;
  }
}

.text-color {
  color: rgb(250, 250, 186);
}

.modal-overlay {
  backdrop-filter: blur(8px);
  background: rgba(255, 255, 255, 0.1);
  z-index: 55;
}

.modal-content {
  z-index: 56;
  position: relative;
}
