.slide-up {
  animation: slideUp 0.3s ease-in forwards;
}

.slide-down {
  animation: slideDown 0.3s ease-in forwards;
}

/* Ensure content stays in final position */
.slide-up,
.slide-down {
  will-change: transform;
  animation-fill-mode: both;
}

@keyframes slideUp {
  from {
    opacity: 0;
    transform: translateY(250px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes slideDown {
  from {
    opacity: 0;
    transform: translateY(-250px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.progress-ring-container {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.progress-ring {
  transform: rotate(-90deg);
  position: absolute;
}

.progress-ring-circle {
  transition: stroke-dashoffset 0.6s ease-in-out;
}

.progress-ring-circle.glowing {
  animation: gradientBorder 2s linear infinite;
}

.icon-container {
  position: absolute;
  inset: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

@keyframes gradientBorder {
  0% {
    stroke: #f85802;
    filter: drop-shadow(0 0 0px #f85802);
  }

  25% {
    stroke: #f60766;
    filter: drop-shadow(0 0 5px #f60766);
  }

  50% {
    stroke: #fd0606;
    filter: drop-shadow(0 0 8px #fd0606);
  }

  75% {
    stroke: #f60766;
    filter: drop-shadow(0 0 5px #f60766);
  }

  100% {
    stroke: #f85802;
    filter: drop-shadow(0 0 0px #f85802);
  }
}

.progress-ring-container.animating {
  border-radius: 50%;
}

@keyframes containerGlow {
  0%,
  100% {
    box-shadow: 0 0 0 rgba(255, 111, 36, 0);
  }

  50% {
    box-shadow: 0 0 15px rgba(255, 111, 36, 0.5);
  }
}
